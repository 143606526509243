import React, { useEffect, useReducer } from "react"
/** @jsx jsx */
import Amplify from "@aws-amplify/core";
// import Analytics from '@aws-amplify/analytics';
import { API, Storage, graphqlOperation, Auth/*Cache, Hub, Logger*/ } from "aws-amplify"
import { listShotwireAssetsDeltas, listAssetsByCategory } from "../graphql/queries"

import { Container, Box, jsx } from 'theme-ui'
import { graphql, useStaticQuery, Link, navigate } from 'gatsby'
// import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Image from 'react-bootstrap/Image'
import styled from '@emotion/styled'
import InfoCard from '../@geocine/gatsby-theme-organization/components/InfoCard'
import Layout from '../@geocine/gatsby-theme-organization/components/Layout'
// import InputGroup from 'react-bootstrap/InputGroup'
import Form from 'react-bootstrap/Form'


import Helmet from 'react-helmet'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVideo, faDownload, faCartPlus, faHome, faEnvelope, faPhone, faPlay, faPause, faSpinner, faUpload, faTrashAlt, faTimesCircle } from "@fortawesome/free-solid-svg-icons";

import ReactPlayer from 'react-player'
import {v4 as uuid} from 'uuid';


// import Layout from "../components/layout"
// import SEO from "../components/seo"

import 'src/css/theme.scss'
import favicon from '../@geocine/gatsby-theme-organization/assets/favicon.ico'
import '@fortawesome/fontawesome-svg-core/styles.css'
import { config } from '@fortawesome/fontawesome-svg-core'

import awsconfig from "../aws-exports";
import { AppsContext } from '../AppsProvider'

import { Picker } from 'aws-amplify-react';


Amplify.configure(awsconfig);
// Storage.configure({ track: true })
// Prevent fontawesome from dynamically adding its css since we did it manually above
config.autoAddCss = false

const WatermarkLogoDiv = styled('div')`
  position: absolute;
  top: 20px;
  left: 35px;
  opacity: 0.6;
`

const UserOpsDiv = styled('div')`
  
  top: 10px;
  left: 0px;
  
`

const pickerButton = {
  marginLeft: '20px',
  padding: '12px 45px', 
  backgroundColor: 'red',
  color: 'white',
  fontSize: '.75rem',
  cursor: 'pointer',
  border: '1px solid red'
}

const MyTheme = {

    signInButtonIcon: { 'display': 'none' },
    googleSignInButton: { 'backgroundColor': 'red', 'borderColor': 'red' },
    button: { 'backgroundColor': 'red', 'border': '1px solid red' },
    navButton: { 'backgroundColor': 'red', 'borderColor': 'red' },
    photoPickerButton: {'backgroundColor': '#828282', 'border': '1px solid #828282' , position: 'relative'}

}


// export const pickerPicker = {
// position: 'relative'
// };

// const AppTheme = {
// pickerPicker: pickerPicker
// }

// export default AppTheme;

const userGroupsMap = {
  Uploaders:'Uploaders',
  Editors:'Editors',
}

const bodyText = 'rgba(255,255,255, .7)'//`${props => props.theme.colors.background}` 


const AdminQrsPage = () => {
  let userInfo = null, uRoles = null, uGroups = null
  // console.log('location.state:',location.state)
   
  // const [state, dispatch] = useReducer(reducer, initialState);
  const appsContext = React.useContext(AppsContext)
  let uploader = {}, isProxyProcessing = []

  // const uploadCompleted = appsContext.state.uploadCompleted, isUploading = appsContext.state.isUploading

  // const assetObj = (location.state === null) ? appsContext.state.videoPlayer.assetObj : location.state.assetObj
  
  // const vPlayer = { playing: false, isDownloading: false, hrDlSrc: '', assetObj: assetObj }

  
  // const onChange = (e) => {
  //     const file = e.target.files[0];
  //     Storage.put('example.png', file, {
  //         contentType: 'image/png'
  //     })
  //     .then (result => console.log(result))
  //     .catch(err => console.log(err));
  // }

  // const handleChange = (event) => {
  //   const { target: { value, files } } = event
  //   const fileForUpload = files[0]
  //   updateProductName(fileForUpload.name.split(".")[0])
  //   updateFile(fileForUpload || value)
  // }



  // async createProduct = (event) => {
  //   event.preventDefault()
  //   if (file) {
  //     const extension = file.name.split(".")[1]
  //     const { type: mimeType } = file
  //     const key = `images/${uuid()}${productName}.${extension}`      
  //     const url = `https://${bucket}.s3.${region}.amazonaws.com/public/${key}`
  //     const inputData = { name: productName , image: url }
      
  //     try {
  //       await Storage.put(key, file, {
  //         contentType: mimeType
  //       })
  //       console.log('inputData: ', inputData)
  //       await API.graphql(graphqlOperation(CreateProduct, { input: inputData }))
  //       console.log('successfully created product!')
  //     } catch (err) {
  //       console.log('error: ', err)
  //     }
  //   }
  // }
   const handleOnchange = (e) => {
    // console.log('e.target.id:',e.target.id)
    // console.log('e.target.value:',e.target.value)
    if(e.target.id==='userGroups'){
      // console.log('userGroups')
      // console.log('e.target.value:',e.target.value)
      // console.log('appsContext.state.usersListForm:',appsContext.state.usersListForm)
      // appsContext.dispatch({type:'METAFORM', metaForm: { ...appsContext.state.metaForm, yr: e.target.value} })
      appsContext.dispatch({type:'USERSLISTFORM', usersListForm: { ...appsContext.state.usersListForm, userGroups: e.target.value} })

    }
    // else if(e.target.id==='setDateMonth'){
    //   // console.log('month')
    //   // console.log('e.target.value:',e.target.value)
    //   appsContext.dispatch({type:'METAFORM', metaForm: { ...appsContext.state.metaForm, mo: e.target.value} })
    // }else if(e.target.id==='setDateDay'){
    //   // console.log('day')
    //   // console.log('e.target.value:',e.target.value)
    //   appsContext.dispatch({type:'METAFORM', metaForm: {...appsContext.state.metaForm, day: e.target.value} })
    // }else if(e.target.id==='category'){
    //   // console.log('e.target.value:',e.target.value)
    //   appsContext.dispatch({type:'METAFORM', metaForm: {...appsContext.state.metaForm, category: e.target.value} })
    // }else if(e.target.id==='archived'){
    //   // console.log('e.target.value:',e.target.value)
    //   appsContext.dispatch({type:'METAFORM', metaForm: {...appsContext.state.metaForm, archived: e.target.value} })
    // }


  }

  const handleUserCheckBoxChange = (e) => {
    // console.log('userCheckbox:', e.target.value)
    // console.log('appsContext.state.usersListForm:',appsContext.state.usersListForm)
    appsContext.dispatch({type:'USERSLISTFORM', usersListForm: { ...appsContext.state.usersListForm, selectedUsers: e.target.value} })

  }

  async function addToGroup() { 
    // console.log('usersListForm:',appsContext.state.usersListForm)
    let apiName = 'AdminQueries';
    let path = '/addUserToGroup';
    let myInit = {
        body: {
          "username" : appsContext.state.usersListForm.selectedUsers,
          "groupname": appsContext.state.usersListForm.userGroups
        }, 
        headers: {
          'Content-Type' : 'application/json',
          Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        } 
    }
    try{
      const postRes = await API.post(apiName, path, myInit);
      // console.log('postRes:', postRes)
            return postRes
    }catch(err){
      // console.log('addToGroup err:', err)
      return err
    }
    

    
  }


let nextToken;

async function listUsers(limit){
  let apiName = 'AdminQueries';
  let path = '/listUsers';
  let myInit = { 
      queryStringParameters: {
        // "groupname": "Editors",
        "limit": limit,
        "token": nextToken
      },
      headers: {
        'Content-Type' : 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
      }
  }
  const { NextToken, ...rest } =  await API.get(apiName, path, myInit);
  // console.log('rest->',rest.Users)
  appsContext.dispatch({type:"USERSLIST", usersList:rest.Users, nextToken: NextToken})

  nextToken = NextToken;
  return rest;
}

async function listAdmins(limit){
  let apiName = 'AdminQueries';
  let path = '/listUsersInGroup';
  let myInit = { 
      queryStringParameters: {
        "groupname": "Admins",
        "limit": limit,
        "token": nextToken
      },
      headers: {
        'Content-Type' : 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
      }
  }
  const { NextToken, ...rest } =  await API.get(apiName, path, myInit);
  // console.log('rest->',rest)
  nextToken = NextToken;

  appsContext.dispatch({type:"USERSLIST", usersList:rest.Users, nextToken: NextToken})

  return rest;
}

async function listGroup(limit){
     
  // console.log('appsContext.state.usersListForm.userGroups:',appsContext.state.usersListForm.userGroups)
  if(appsContext.state.usersListForm.userGroups===null){
   // console.log('no group')
   return "no group selected"
 }else{
    let apiName = 'AdminQueries';
    let path = '/listUsersInGroup';
    let myInit = { 
        queryStringParameters: {
          "groupname": appsContext.state.usersListForm.userGroups,
          "limit": limit,
          "token": nextToken
        },
        headers: {
          'Content-Type' : 'application/json',
          Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        }
    }
    const { NextToken, ...rest } =  await API.get(apiName, path, myInit);
    // console.log('rest->',rest)
    nextToken = NextToken;

    appsContext.dispatch({type:"USERSLIST", usersList:rest.Users, nextToken: NextToken})

    return rest;    
  }

}

  const handleDelete = (e, key) => {
    e.preventDefault()
    // console.log('key:', key)
    Storage.remove(key, {level: 'private'})
    .then(result => {
      // console.log('delete result:',result)
      appsContext.dispatch({ type: "FILEDELETECOUNTER", fileDeleteCounter: (appsContext.state.fileDeleteCounter+1) })
    })
    .catch(err =>{ 
      // console.log(err)
    });
  }

  const handleProxyDelete = (e, key) => {
    e.preventDefault()
    // console.log('key:', key)
    // console.log(appsContext.state.proxyList[key].id)
    deletaDeltaAsset(appsContext.state.proxyList[key].id)
    // console.log('fileDeleteCounter:', appsContext.state.fileDeleteCounter)
    
  }

  const handleDownload = (e, key) => {
    e.preventDefault()
    // console.log('key:', key)
    Storage.get(key, {
      level: 'private',
      // expires: 300
    })
    .then(result => {
      window.open(result, '_blank');
      //use navigate here to start auto download
      // console.log('download Link:',result)
          // navigate(
          //     result,
          //     // {
          //     //   state: { searchform },
          //     // }
          //   )
    })
    .catch(err =>{
      // console.log(err)
    });
  }

  const handleProxyDownload = (e, key) => {
    e.preventDefault()
    // console.log('key:', key)
    // console.log(appsContext.state.proxyList[key])
    // console.log(appsContext.state.proxyList[key].mp4Urls[1])
    window.open(appsContext.state.proxyList[key].mp4Urls[1].replace(/\{|\}/g,'').substr(2), '_blank');
    // "{S=https://d7byjd2oa0k4h.cloudfront.net/d28d692c-e0c4-4bbd-bf7d-d9114d0bb709/mp4/1e01704e-4019-4982-8cb1-6c0b3542576b_16995PCNVEX_HaileyClauson_TMF_Mp4_Avc_Aac_16x9_6400x360p_24Hz_4.5Mbps_qvbr.mp4}"

  }

  const handlePublish = (e, fileListIndex) => {
    e.preventDefault()
    
    
   

  }

  const handleMeta = (e, fileListIndex) => {
    e.preventDefault()
    let qstr = ''
    // console.log('key:', key)
    // console.log(appsContext.state.proxyList[fileListIndex])
    // console.log(appsContext.state.proxyList[key].mp4Urls[1])
    // console.log(appsContext.state.proxyList[fileListIndex].thumbNailUrl)
    const vPlayer = {}
    vPlayer.image = appsContext.state.proxyList[fileListIndex].thumbNailUrl
    vPlayer.assetObj= appsContext.state.proxyList[fileListIndex] 
    appsContext.dispatch({ type: "VPLAYER", vPlayer });

    if(appsContext.state.proxyList[fileListIndex].realSetId){
      qstr = "arsid="+appsContext.state.proxyList[fileListIndex].realSetId+"&asd="+appsContext.state.proxyList[fileListIndex].setDate+"&an="+appsContext.state.proxyList[fileListIndex].name
    }else qstr = "aid="+appsContext.state.proxyList[fileListIndex].id
    
    navigate(
      "/VideoPlayer/?"+qstr,
      // {
      //   state: { searchform },
      // }
    )
    // if(e.currentTarget.id === 'playButton') 


  }

  const handleProxy = (e, fileListIndex) => {
    e.preventDefault()
    
    // console.log('button target:', e.target)

    isProxyProcessing = appsContext.state.isProxyProcessing
    isProxyProcessing[fileListIndex] = true
    appsContext.dispatch({ type: "PROXYSTATS", isProxyProcessing })

    const newId = uuid()

    Storage.get(appsContext.state.fileList[fileListIndex].key, {
      level: 'private',
      // expires: 300
    })
    .then(result => {
      // console.log('download Link:',result)
      createVideoProxy({srcUrl: result, name: newId + '_' + appsContext.state.fileList[fileListIndex].key})
     
    })
    .then(result =>{
      // console.log('createVideoProxy result:', result)
      const asset = { id: newId, name:newId+'_'+appsContext.state.fileList[fileListIndex].key, fileName: newId+'_'+appsContext.state.fileList[fileListIndex].key, archived: 0, assetType: 3, category:'Ingest', workflowStatus: 'Ingest', uploader: appsContext.state.user.username }
      // console.log('asset:', asset)
      addAssetDelta( asset ) 
      // appsContext.dispatch({ type: "PROXYSTATS", isProxyProcessing: false })
      
    })
    .catch(err =>{
      
      // console.log(err)

    });

     setTimeout(() => {
        // console.log('finished process.');
        isProxyProcessing = appsContext.state.isProxyProcessing
        isProxyProcessing[fileListIndex] = false
        appsContext.dispatch({ type: "PROXYSTATS", isProxyProcessing })
      }, 15000);
     
  }

  const handleUpload = (data, arg) => {

          /* Do something when the user has signed-in */
          // try{
          //   getVideoUrl('hrdl')
          //   vPlayer.isDownloading = true
          //   appsContext.dispatch({ type: "VPLAYER", vPlayer });
          
          //   // appsContext.dispatch({ type: "SRC", signedUrl: signedUrl.videoUrl });
          // }catch(err){
            
          //   console.log('error: ', err)
          // }
        // console.log('arg: ', arg)
        // console.log('data: ', data)
          // Storage.put(data.name, data.file, {
          //     level: 'private',
          //     contentType: data.type
          // })
          // .then (result => console.log(result))
          // .catch(err => console.log(err));

          // //below is the react native version that supports blobs
          // uploadImage = async uri => {
          //   const response = await fetch(uri);
          //   const blob = await response.blob();
          //   const fileName = 'profileImage.jpeg';
          //   await Storage.put(fileName, blob, {
          //     contentType: 'image/jpeg',
          //     level: 'private'
          //   }).then(data => console.log(data))
          //     .catch(err => console.log(err))
          // }
  }

  const handleJobTemplate = (e) => {
    e.preventDefault()
    console.log('get job template')
    createJobTemplate()
  }

  async function createVideoProxy(srcObj) {
    // console.log('image:', appsContext.state.videoPlayer.image)
    
      try{
        
        // console.log('hrDlSrc: ',appsContext.state.videoPlayer.hrDlSrc)
        const createProxy = await API.get('shotwireAPI', '/createVideoProxy', {
                        'queryStringParameters': {
                          // 'setId': appsContext.state.videoPlayer.assetObj.realSetId,
                          // 'setDate': appsContext.state.videoPlayer.assetObj.setDate,
                          // 'setName': appsContext.state.videoPlayer.assetObj.name,
                          // 'assetName': (dlType === 'hrdl') ? appsContext.state.videoPlayer.assetObj.highRezFileName : appsContext.state.videoPlayer.assetObj.highRezFileName.replace('.mp4','-h264.mp4'),
                          'jobAction': 'createProxy',
                          's3srcUrl' : srcObj.srcUrl,
                          'assetName' : srcObj.name,
                          'jobTemplate': 'Shotwire-Watermarked-Proxies'                      
                        }
                      });
        // console.log('createProxy:', createProxy)
        return createProxy
        // add video to delta table once proxy is created
        // appsContext.dispatch({type:"CREATEVIDEOPROXY", newAsset:{ name:srcObj.name, fileName: srcObj.name, archived: 0, assetType: 3, s3srcUrl: srcObj.srcUrl, proxyInfo: 'pending' } })
      // appsContext.addAssetDelta({ name:srcObj.name, fileName: srcObj.name, archived: 0, assetType: 3, s3srcUrl: srcObj.srcUrl, proxyInfo: 'pending' })
     
        
      }catch(err){
        //add an image placehold for assets with no image
        // dispatch({ type: "QUERY", albums: [{id:`none`,name:`none`}] });
        // console.log('error: ', err)
      }
      

  }

  async function createJobTemplate() {
    // console.log('image:', appsContext.state.videoPlayer.image)
    
      try{
        
        // console.log('hrDlSrc: ',appsContext.state.videoPlayer.hrDlSrc)
        const jobTempl = await API.get('shotwireAPI', '/createVideoProxy', {
                        'queryStringParameters': {
                          'jobAction': 'getJobTempate',
                          // 'assetName' : srcObj.name,
                          'jobTemplate': 'Shotwire-Watermarked-Proxies'                      
                        }
                      });
        console.log('jobTempl:', jobTempl)
        return jobTempl
        // add video to delta table once proxy is created
        // appsContext.dispatch({type:"CREATEVIDEOPROXY", newAsset:{ name:srcObj.name, fileName: srcObj.name, archived: 0, assetType: 3, s3srcUrl: srcObj.srcUrl, proxyInfo: 'pending' } })
      // appsContext.addAssetDelta({ name:srcObj.name, fileName: srcObj.name, archived: 0, assetType: 3, s3srcUrl: srcObj.srcUrl, proxyInfo: 'pending' })
     
        
      }catch(err){
        //add an image placehold for assets with no image
        // dispatch({ type: "QUERY", albums: [{id:`none`,name:`none`}] });
        console.log('error: ', err)
      }
      

  }

  const createAssetDelta = `mutation createAssetDelta($input: CreateShotwireAssetsDeltaInput!) {
      createShotwireAssetsDelta(input: $input) {
        id
        assetId
        name

      }
    }
    `;

  async function addAssetDelta(assetObj) {
    // const asset = {...assetObj, archived: 1}
      // console.log('props:',props)
      // console.log('albumid:',albumid)
      // const photo = { 
      //   id: id,
      //   // owner: props.album.owner,
      //   photoAlbumId: albumid,
      //   // bucket: bucketName,
      //   // thumbnail: sizes.thumbnail,
      //   // fullsize: sizes.fullsize,
      //   // createdAt: new Date().getTime()
      // };
    try{
      await API.graphql(graphqlOperation(createAssetDelta, { input: assetObj }));
    } catch (err){
      console.log('addasset err:',err)
    }
    
    }

    const deleteAssetDelta = `mutation deleteAssetDelta($input: DeleteShotwireAssetsDeltaInput!){
      deleteShotwireAssetsDelta(input: $input){
        id
        assetId
        name

      }
    }`

    async function deletaDeltaAsset(id) {
    
      
      try{
        await API.graphql(graphqlOperation(deleteAssetDelta, { input: {id: id} }));
        appsContext.dispatch({ type: "FILEDELETECOUNTER", fileDeleteCounter: (appsContext.state.fileDeleteCounter+1) })
      } catch (err){
        console.log('addasset err:',err)
      }
      
    
    }


    const proxyListQuery = `query ListAllshotwireAssetsDeltas($filter: ModelShotwireAssetsDeltaFilterInput, $limit: Int, $nextToken: String){
      listShotwireAssetsDeltas(filter: $filter, limit: $limit, nextToken: $nextToken){
        items {
          id
          fileName
          fileInfo
          fileSize
          assetType
          highRezFileName
          companyId
          name
          setDate
          realSetId
          assetId
          headline
          caption
          keywords
          byline
          suppCat
          city
          video_length
          archived
          thumbNailUrl
          mp4Urls
          hlsUrl
          dashUrl
          mp4Outputs
          category
        }
        nextToken
        
      }
    }`

    const proxyListIndexQuery = `query ListAssetsDeltasIndex($category: String!,$filter: TableShotwireAssetsDeltaFilterInput, $limit: Int, $nextToken: String, $scannedCount: Int){
      listShotAssetsDeltasIndex(category: $category, filter: $filter ,limit: $limit, nextToken: $nextToken, scannedCount: $scannedCount){
        items {
          id
          category
          setDate 
          name
          fileName
          fileInfo
          fileSize
          assetType
          highRezFileName
          companyId
          realSetId
          assetId
          headline
          caption
          keywords
          byline
          suppCat
          city
          video_length
          archived
          thumbNailUrl
          mp4Urls
          hlsUrl
          dashUrl
          mp4Outputs
          uploader

        }
        nextToken
        scannedCount
      }
    }`

    const ListShotAssetsDeltasUploaderIndex = `query ListShotAssetsDeltasUploaderIndex($uploader: String!, $category: String!, $filter: TableShotwireAssetsDeltaFilterInput, $limit: Int, $nextToken: String){
      listShotAssetsDeltasUploaderIndex(uploader: $uploader, category:$category ,filter: $filter ,limit: $limit, nextToken: $nextToken){
        items {
          id
          category
          fileName
          fileInfo
          fileSize
          assetType
          highRezFileName
          companyId
          name
          setDate
          realSetId
          assetId
          headline
          caption
          keywords
          byline
          suppCat
          city
          video_length
          archived
          thumbNailUrl
          mp4Urls
          hlsUrl
          dashUrl
          mp4Outputs
          uploader
        }
        nextToken
      }
    }`
// listShotAssetsDeltasIndex(category: "Ingest", filter: {archived: {eq: 0}} ,limit: 50, nextToken: null){
//         items {
//           id
//           fileName
//           fileInfo
//           fileSize
//           assetType
//           highRezFileName
//           companyId
//           name
//           setDate
//           realSetId
//           assetId
//           headline
//           caption
//           keywords
//           byline
//           suppCat
//           city
//           video_length
//         }
//         nextToken
//       }
//     }
    async function getAdminProxyList() {
    
    // console.log('getting getAdminProxyList...')
      try{
        // console.log('userInfo.username:', userInfo.username)
        // console.log('appsContext.state.proxyListNextToken:',appsContext.state.proxyListNextToken)
        // const proxyList = await API.graphql(graphqlOperation(proxyListIndexQuery,{category: "Ingest",filter: {or :[{archived: {eq: 0}}, {category: {eq:"Ingest"}}]}, limit: 9999999, nextToken: appsContext.state.proxyListNextToken}));
        // const proxyList = await API.graphql(graphqlOperation(proxyListQuery, {filter:{ archived: {eq:0}}, limit:100, nextToken:null }));
        const proxyList = await API.graphql(graphqlOperation(listAssetsByCategory, {category: "Ingest", limit:100, nextToken:null }));
        

        appsContext.dispatch({ type: "PROXYLIST", proxyListConnection: {items: proxyList.data.listAssetsByCategory.items, proxyListNextToken: appsContext.state.proxyListNextToken} });
        


//should be this but commenting out for now
        // const proxyList = await API.graphql(graphqlOperation(proxyListIndexQuery,{uploader: appsContext.state.user.username ,category: "Ingest", filter: null, limit: 100, nextToken: appsContext.state.proxyListNextToken}));

        // console.log('proxyList:', proxyList)
        // appsContext.dispatch({ type: "PROXYLIST", proxyListConnection: {items: proxyList.data.listShotAssetsDeltasIndex.items, proxyListNextToken: appsContext.state.proxyListNextToken} });
        



      }catch(err){
        
        //add an image placehold for assets with no image
        // appsContext.dispatch({ type: "QUERY", albums: [{id:`none`,name:`none`, headline:`none`}] });
        console.log('error: ', err)
      }
      

    }

    async function getMyProxyList() {
    
    console.log('getting getMyProxyList...')
      try{
        // console.log('userInfo.username:', userInfo.username)
        // console.log('appsContext.state.proxyListNextToken:',appsContext.state.proxyListNextToken)
        // const proxyList = await API.graphql(graphqlOperation(proxyListIndexQuery,{category: "Ingest",filter: {or :[{archived: {eq: 0}}, {category: {eq:"Ingest"}}]}, limit: 9999999, nextToken: appsContext.state.proxyListNextToken}));
        // const proxyList = await API.graphql(graphqlOperation(proxyListQuery,{filter: {archived: {eq: 0}}, limit: 999999, nextToken: null}));
        const proxyList = await API.graphql(graphqlOperation(ListShotAssetsDeltasUploaderIndex,{uploader: appsContext.state.user.username ,category: "Ingest", filter: null, limit: 100, nextToken: appsContext.state.proxyListNextToken}));

        // console.log('proxyList:', proxyList)
        appsContext.dispatch({ type: "PROXYLIST", proxyListConnection: {items: proxyList.data.listShotAssetsDeltasUploaderIndex.items, proxyListNextToken: appsContext.state.proxyListNextToken} });
        



      }catch(err){
        
        //add an image placehold for assets with no image
        // appsContext.dispatch({ type: "QUERY", albums: [{id:`none`,name:`none`, headline:`none`}] });
        console.log('error: ', err)
      }
      

    }

  // const handlePlay = (e) => {
  //   e.preventDefault()
  //   console.log('onPlay')
  //   // setState({ playing: true })
  //   vPlayer.playing = true
  //   appsContext.dispatch({ type: "VPLAYER",  vPlayer });
  // }

  // const handlePause = (e) => {
  //   if (e){ 
  //     e.preventDefault()
  //     console.log('onPause')
  //     // setState({ playing: false })
  //     vPlayer.playing = false
  //     appsContext.dispatch({ type: "VPLAYER",  vPlayer });
  //       // event.preventDefault();
  //       // console.log('props.location.pathname:', props.location.pathname)
  //         // console.log('clicked menu link:', event.target)
  //         // currentCategory = category
  //         // appsContext.dispatch({ type: "VPLAYER",  currentCategory});
  //     }
  // }
  const onUpdateShotwireAssetsDelta = `subscription OnUpdateShotwireAssetsDelta {
  onUpdateShotwireAssetsDelta {
    id
    fileName
    category
    uploader
    fileInfo
    fileSize
    assetType
    highRezFileName
    companyId
    name
    setDate
    realSetId
    assetId
    headline
    caption
    keywords
    byline
    suppCat
    city
    video_length
    archived
    thumbNailUrl
    mp4Urls
    hlsUrl
    dashUrl
    mp4Outputs
  }
}
`;


  useEffect(() => {
    let subscription = null
        try{
                // Hub.listen('auth', listener);
                // appsContext.state.videoPlayer.hrDlSrc = ''
                //  console.log('appsContext.state.videoPlayer.hrDlSrc: ',appsContext.state.videoPlayer.hrDlSrc)
                // console.log('appsContext.state.isProxyProcessing:',appsContext.state.isProxyProcessing)
                // console.log('user data:', appsContext.state.user)
                
                if(appsContext.state.user){
                  const  userInfo  = appsContext.state.user
                  // console.log('userInfo: ', userInfo)

                  if(typeof userInfo.signInUserSession.idToken.payload[`cognito:groups`] != 'undefined'){
                    uGroups = userInfo.signInUserSession.idToken.payload[`cognito:groups`]
                    // console.log('uGroups:',uGroups)
                    // console.log('uRoles: ', uRoles)
                    if( uGroups.some(r => ['Admins'].indexOf(r) >= 0) ){
                      // console.log('Admin user.')
                       appsContext.dispatch({ type: "ISADMIN", isAdmin:true }) 
                     }
                    // console.log('isAdmin:', isAdmin)
                  }
                }
                //*/
                
                // getProxyList()
                // if(appsContext.state.isUploader) getMyProxyList()
                // if(appsContext.state.isAdmin) getAdminProxyList()

                // Storage.list('', {
                //     level: 'private'
                //   })
                //   .then(result => {
                //     // console.log('storage listing: ',result)
                //     appsContext.dispatch({ type: "FILELIST", fileList: result })
                //   })
                //   .catch(
                //     err => {
                //       // console.log(err)
                //     })
   
 

              
              // getData();
              
              // subscription = API.graphql(graphqlOperation(onUpdateShotwireAssetsDelta)).subscribe({
              //     next: eventData => {
              //       const newAsset = eventData.value.data.onUpdateShotwireAssetsDelta;
              //       // console.log('newAsset:',newAsset)
              //       // console.log('appsContext.state.proxyList',appsContext.state.proxyList)
              //       let pList = appsContext.state.proxyList
              //       // console.log('pList:', pList)
              //       pList.push(newAsset)
              //       // console.log('pList2:', pList)
              //       // dispatch({ type: "SUBSCRIPTION", newAsset });
              //       appsContext.dispatch({ type: "PROXYLIST", proxyListConnection: {items: pList, proxyListNextToken: appsContext.state.proxyListNextToken} });
        
              //     }
              //   });
          } catch (error) {
            // console.log('useEffect error: ',error)
          }     

                return () => {
                  // if(subscription != null) subscription.unsubscribe();
                  // Hub.remove(channel: string | RegExp, listener: callback)
                  // Hub.remove( 'auth' )
                  // vPlayer.videoUrl = '' 
                  // vPlayer.signedUrl = ''
                  // vPlayer.hrDlSrc = ''
                  // vPlayer.assetObj= {} 
                  // appsContext.dispatch({ type: "VPLAYER", vPlayer });

                };
          
      }, [ appsContext.state.uploadCounter, appsContext.state.fileDeleteCounter, appsContext.state.isProxyProcessing, appsContext.state.user, uRoles, appsContext.state.isAdmin, appsContext.state.isUploader, appsContext.state.usersList ]);

  


  //settting auth state to 'loading' hide the default signin form
if (appsContext.state.user !== null) {
  // const userInfo = appsContext.state.user
//get user roles is admin
// console.log('admin->', {isAdmin})
  if(appsContext.state.isAdmin){
     return(
          <Layout>
          <Helmet>
            <title>Shotwire Admin Center</title>
            <link rel="icon" href={favicon} type="image/x-icon" />
          </Helmet>
          <Container sx={{
            color: bodyText
          }}>
         
          <div sx={{
             
              padding: '10px',
              // backgroundColor: '#888'
              // borderBottom: '1px dashed #333'
          }}>
          <h3>Admin Center</h3>
          </div>
          </Container>
          <Container>
            <Row>
              <Col sm={12} lg={10}>              
                <div sx={{
                    padding: '10px',
                // backgroundColor: 'pink',
                width: '100%',
                height: 'auto'
                // objectFit: 'contain'
                      }}>

<Row sx={{
              // borderBottom: '2px solid gray',
                    padding: '1rem',
                // backgroundColor: 'blue',
                // width: '100%',
                // height: 'auto'
                // objectFit: 'contain'
                      }}>
<h4>Users</h4>



</Row>
<Row sx={{
              borderBottom: '2px solid gray',
                    padding: '1rem',
                // backgroundColor: 'blue',
                // width: '100%',
                // height: 'auto'
                // objectFit: 'contain'
                      }}>
                      <Form.Group controlId="userGroups">
            <Form.Label>Groups</Form.Label>
            <Form.Control as="select" value={(appsContext.state.usersListForm.userGroups)?appsContext.state.usersListForm.userGroups:''} onChange={e => handleOnchange(e)}>
            <option>SELECT USER GROUPS</option>
        {
          Object.keys(userGroupsMap).map((keyName, i) => (

              <option value={keyName} key={keyName}>{userGroupsMap[keyName]}</option>

            ))

        }
        </Form.Control>
        </Form.Group>
<Button onClick={addToGroup} sx={{marginRight:'10px'}}>Add to Group</Button>{' '}
<Button onClick={() => listGroup(10)} sx={{marginRight:'10px'}}>List Group</Button>{' '}
<Button onClick={() => listUsers(10)} sx={{marginRight:'10px'}}>List All Users</Button>{' '}
<Button onClick={() => listAdmins(10)} sx={{marginRight:'10px'}}>List Admins</Button>{' '}
</Row>
{
//*
 appsContext.state.usersList.map((user,key) => (
            <Row key={key} sx={{
              borderBottom: '1px dashed rgba(0,0,0, .2)',
                    padding: '1rem',
                // backgroundColor: 'blue',
                // width: '100%',
                // height: 'auto'
                // objectFit: 'contain'
                      }}>
            <Col sm={12} lg={3}>
             <Form.Check type="checkbox" value={user.Username} onChange={e=> handleUserCheckBoxChange(e)} label={' '+ (key+1) + '. ' + user.Username} />
               
            </Col>
            
            <Col sm={12} lg={1}>{user.Enabled === true ? 'Enabled':'Disabled' }</Col>
            <Col sm={12} lg={2}>Status: {user.UserStatus}-{user.UserCreateDate} </Col>
            <Col sm={12} lg={2}>Updated: {user.UserLastModifiedDate}</Col>
            <Col sm={12} lg={1}>

            </Col>
            
            

            
            

            </Row>
          ))
 //*/
}
                    </div>
                  <div sx={{
                    padding: '10px',
                // backgroundColor: 'blue',
                width: '100%',
                height: 'auto'
                // objectFit: 'contain'
                      }}>
<Row sx={{
              borderBottom: '2px solid gray',
                    padding: '1rem',
                // backgroundColor: 'blue',
                // width: '100%',
                // height: 'auto'
                // objectFit: 'contain'
                      }}>
<h4></h4>
{ 

 //  appsContext.state.isProxyProcessing.includes(true) ? (
 //          <div sx={{
 //  paddingLeft: '10px',
 // }} >
 //          <FontAwesomeIcon
 //            icon={faSpinner}
 //            size="sm"
 //            sx={{
 //              // marginRight: '0.5rem'
 //            }}
 //            spin
 //          />
 //          </div>
 //        ) : (
 //          <div sx={{
 //  paddingLeft: '10px',
 // }}></div>
 //        )
    }
</Row>
{
/*
 appsContext.state.proxyList.map((file,key) => (

            <Row key={key} sx={{
              borderBottom: '1px dashed rgba(0,0,0, .2)',
                    padding: '1rem',
                // backgroundColor: 'blue',
                // width: '100%',
                // height: 'auto'
                // objectFit: 'contain'
                      }}>
            <Col sm={12} lg={6}><b>{key+1}.</b> {file.name}<br />
              {(file.thumbNailUrl!==null) ? 
                <div>
                  <span><b>Uploader:</b> {file.uploader}</span><br />
                  <Image 
                  src={file.thumbNailUrl}
                  alt={file.name}
                  height={168}
                  // width={295}
                  width={'100%'}
                  // width={ (assetId=='none') ? `300` : '100%'}
                  // fluid
                  />
                  </div>

                : <b>PROXY PENDING</b>}
            </Col>
          
           
          
            <Col sm={12} lg={6}>
                <Button sx={{ margin: '2px' }} variant="dark" href="#!" size="md" onClick={e => handleProxyDownload(e, key)}>Download</Button> 
                <Button sx={{ margin: '2px' }} variant="dark" href="#!" size="md" onClick={e => handleMeta(e, key)}>Meta / Publish</Button> 
                {
                // <Button sx={{ margin: '2px' }} variant="dark" href="#!" size="md" onClick={e => handlePublish(e, key)} disabled>Publish</Button> 
                }
                <Button sx={{ margin: '2px' }} variant="dark" href="#!" size="md" onClick={e => handleProxyDelete(e, key)}>
                <FontAwesomeIcon
                    icon={faTimesCircle}
                    size="lg"
                    sx={{
                      // marginRight: '0.1rem'
                    }}
                  />
                </Button>
            </Col>
          </Row>
          
          ))
 //*/
}
                </div>
              </Col>
              <Col sm={12} lg={2}>
              
                  
                  
                  <div sx={{
                        width: '100%',
                        padding: '0px',
                        paddingBottom: '20px'
                      }}>
                      
                      <h6 className="text-uppercase mb-4 font-weight-bold">Admin Options</h6>
                    {  
                      // <Button sx={{ marginBottom: '2px' }} variant="dark" href="#!" size="md">
                      //   <FontAwesomeIcon
                      //     icon={faUpload}
                      //     size="lg"
                      //     sx={{
                      //       marginRight: '0.5rem'
                      //     }}
                      //   />{' '}
                      //   Video Upload
                      // </Button>
                      }
    {
/*
                      <Picker 
                        title={ ((appsContext.state.isUploading) ? 'UPLOADING '+Math.round(appsContext.state.uploadCompleted*100)+'%' : 'Upload Video')}
                        theme={MyTheme}
                        onPick={ data => {
                            // console.log(data)
                            uploader.isUploading = true
                            Storage.put(data.name, data.file, {
                                level: 'private',
                                contentType: data.type,
                                progressCallback(progress) {
                                      // console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
                                      
                                      uploader.uploadCompleted = progress.loaded/progress.total
                                      // appsContext.state.uploadCompleted = progress.loaded/progress.total
                                      appsContext.dispatch({ type: "UPLOADER", uploader })
                                }
                            })
                            .then (result => {
                              // console.log(result)
                              uploader.isUploading = false
                              uploader.uploadCompleted = 0
                              uploader.uploadCounter++
                              appsContext.dispatch({ type: "UPLOADER", uploader })
                            })
                            .catch(err => {
                              // console.log(err)
                              uploader.isUploading = false
                              uploader.uploadCompleted = 0
                              uploader.uploadCounter++
                              appsContext.dispatch({ type: "UPLOADER", uploader })
                            });
                          }


                        }
                        // onClick={e => handleUpload(e, 'videoUpload')}
                        track
                        >
                      </Picker>
                      //*/
}
                      <Button sx={{ margin: '2px' }} variant="dark" href="#!" size="md" onClick={e => handleJobTemplate(e)}>Create Watermark Template</Button> 
                  </div>
                  
                  <div sx={{
                        width: '100%',
                        padding: '10px',

                      }}>
                    
                    <p>
                    
                    
                    <br /><br />
                    
                    

                    </p>   
                    


                    
                  </div>

                    <div sx={{
                        width: '100%',
                        padding: '10px',
                        // backgroundColor: '#444'
                      }}
                      >
                   
                      
                      
                      
                      
                    </div>

              </Col>
            </Row>
          </Container>

           
           </Layout>
           )
  }else{
    return(
          <Layout>
          <Container>

          { /*<h3>No Video Info</h3>*/ }
          
          <div sx={{
  padding: '10px',
 }} >
 {
  <span>Refresh Profile</span>
          // <FontAwesomeIcon
          //   icon={faSpinner}
          //   size="2x"
          //   sx={{
          //     // marginRight: '0.5rem'
          //   }}
          //   spin
          // />
}

          </div>
          </Container>
          </Layout>
          )
  }

}else{
        return(
          <Layout>
          <Container>

          { <h3>Not Authorized</h3> }
          
          </Container>
          </Layout>
          )
}

 
}
{ 
 //                        appsContext.state.videoPlayer.isDownloading ? (
 //          <div sx={{
 //  paddingRight: '10px',
 // }} >
 //          <FontAwesomeIcon
 //            icon={faSpinner}
 //            size="sm"
 //            sx={{
 //              // marginRight: '0.5rem'
 //            }}
 //            spin
 //          />
 //          </div>
 //        ) : (
 //          <div sx={{
 //  padding: '0px',
 // }}></div>
 //        )
    }

//if we make our own controls 
// { appsContext.state.videoPlayer.playing ? (
//                 <Button variant="dark" href="#!" size="md" onClick={handlePause}>
//                         <FontAwesomeIcon
//                           icon={faPause}
//                           size="lg"
//                           sx={{
//                             marginRight: '0.5rem'
//                           }}
//                         />
//                       </Button>
// ):(
//                       <Button variant="dark" href="#!" size="md" onClick={handlePlay}>
//                         <FontAwesomeIcon
//                           icon={faPlay}
//                           size="lg"
//                           sx={{
//                             marginRight: '0.5rem'
//                           }}
//                         />
//                       </Button>
//             )   
// }

// <p>
//           Query String:<br />
//           ?setId={ location.state.assetObj.realSetId }&setDate={ location.state.assetObj.setDate }&setName={ location.state.assetObj.name }&assetName={ location.state.assetName }
//           </p>
// <Layout>
//     <SEO title="Celebrity Candids" />
//     <h1>Hi from the second page</h1>
//     <p>Celebrity Candids</p>
//     <Link to="/">Go back to the homepage</Link>
//   </Layout>

export default AdminQrsPage